// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { VendorRow } from '../typings';

const data = [
  {
    "hostname": "bbg-dev.mizunocustom.com",
    "environment": "development",
    "sportId": "baseball",
    "vendorId": "mizunousa",
    "orderMethod": "custom",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoBaseball",
    "hashtag": "MizunoCustom",
    "hideVia": true,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "sbg-dev.mizunocustom.com",
    "environment": "development",
    "sportId": "softball",
    "vendorId": "mizunousa",
    "orderMethod": "custom",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoFastpitch",
    "hashtag": "MizunoCustom",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "bbgs.mizunocustom.com",
    "environment": "staging",
    "sportId": "baseball",
    "vendorId": "mizunousa",
    "orderMethod": "custom",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoBaseball",
    "hashtag": "MizunoCustom",
    "hideVia": true,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "sbgs.mizunocustom.com",
    "environment": "staging",
    "sportId": "softball",
    "vendorId": "mizunousa",
    "orderMethod": "custom",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoFastpitch",
    "hashtag": "MizunoCustom",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "bbg-staging.mizunocustom.com",
    "environment": "staging",
    "sportId": "baseball",
    "vendorId": "mizunousa",
    "orderMethod": "custom",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoBaseball",
    "hashtag": "MizunoCustom",
    "hideVia": true,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "sbg-staging.mizunocustom.com",
    "environment": "staging",
    "sportId": "softball",
    "vendorId": "mizunousa",
    "orderMethod": "custom",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoFastpitch",
    "hashtag": "MizunoCustom",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "mec.bbg-staging.mizunocustom.com",
    "environment": "staging",
    "sportId": "baseball",
    "vendorId": "mec",
    "vendorName": "Mizuno Experience Center",
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Mizuno Experience Center Custom Glove",
    "customerEmailOrderBodyId": "mec",
    "regions": "usa",
    "twitterHandle": "MizunoEXPCenter",
    "hideVia": false,
    "hideSocial": true,
    "currency": "USD"
  },
  {
    "hostname": "mec.sbg-staging.mizunocustom.com",
    "environment": "staging",
    "sportId": "softball",
    "vendorId": "mec",
    "vendorName": "Mizuno Experience Center",
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Mizuno Experience Center Custom Glove",
    "customerEmailOrderBodyId": "mec",
    "regions": "usa",
    "twitterHandle": "MizunoEXPCenter",
    "hideVia": false,
    "hideSocial": true,
    "currency": "USD"
  },
  {
    "hostname": "cda.bbg-staging.mizunocustom.com",
    "environment": "staging",
    "sportId": "baseball",
    "vendorId": "mizunocda",
    "vendorName": "Mizuno Canada",
    "orderMethod": "custom",
    "regions": "canada",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoBaseball",
    "hashtag": "MizunoCustom",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "cda.sbg-staging.mizunocustom.com",
    "environment": "staging",
    "sportId": "softball",
    "vendorId": "mizunocda",
    "vendorName": "Mizuno Canada",
    "orderMethod": "custom",
    "regions": "canada",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoFastpitch",
    "hashtag": "MizunoCustom",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "mizunousa",
    "orderMethod": "custom",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoBaseball",
    "hashtag": "MizunoCustom",
    "hideVia": true,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "baseballglove.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "mizunousa",
    "orderMethod": "custom",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoBaseball",
    "hashtag": "MizunoCustom",
    "hideVia": true,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "mizunousa.baseballglove.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "mizunousa",
    "orderMethod": "custom",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoBaseball",
    "hashtag": "MizunoCustom",
    "hideVia": true,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "mizunocda.baseballglove.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "mizunocda",
    "vendorName": "Mizuno Canada",
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "regions": "canada",
    "twitterHandle": "MizunoBaseball",
    "hashtag": "MizunoCustom",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "mec.baseballglove.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "mec",
    "vendorName": "Mizuno Experience Center",
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Mizuno Experience Center Custom Glove",
    "customerEmailOrderBodyId": "mec",
    "regions": "usa",
    "twitterHandle": "MizunoEXPCenter",
    "hideVia": false,
    "hideSocial": true,
    "currency": "USD"
  },
  {
    "hostname": "prostockathleticsupply.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "prostockathleticsupply",
    "vendorName": "Prostock Athleic Supply",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Prostock Athleic Supply",
    "regions": "canada",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "kirbyssports.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "kirbyssports",
    "vendorName": "Kirbys SFS",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Kirbys SFS",
    "regions": "canada",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "unitedsport.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "unitedsport",
    "vendorName": "United Cycle Source for Sports",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "United Cycle Source for Sports",
    "regions": "canada",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "grandslamcanada.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "grandslamcanada",
    "vendorName": "Grand Slam sports",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Grand Slam sports",
    "regions": "canada",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "baseballtown.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "baseballtown",
    "vendorName": "Baseball Town",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Baseball Town",
    "regions": "canada",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "kahunaverse.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "kahunaverse",
    "vendorName": "Home Run Sports",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Home Run Sports",
    "regions": "canada",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "academy.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "academy",
    "vendorName": "Academy",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Academy",
    "regions": "usa",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "dsg.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "dsg",
    "vendorName": "Dicks Merchandising",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Dicks Merchandising",
    "regions": "usa",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "directsports.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "directsports",
    "vendorName": "Direct Sports Inc",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Direct Sports Inc",
    "regions": "usa",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "baseballsavings.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "baseballsavings",
    "vendorName": "Baseball Savings",
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Baseball Savings",
    "regions": "usa",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "softballfans.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "softballfans",
    "vendorName": "Softball Fans",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Softball Fans",
    "regions": "usa",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "justballgloves.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "justballgloves",
    "vendorName": "Pro Athlete",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Pro Athlete",
    "regions": "usa",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "hitadouble.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "hitadouble",
    "vendorName": "HIT a Double",
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "HITaDouble Custom Orders",
    "regions": "usa",
    "hideVia": true,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "svp.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "svp",
    "vendorName": "SVP Retail",
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Mizuno Custom Glove Design",
    "regions": "usa",
    "hideVia": true,
    "hideSocial": true,
    "currency": "USD"
  },
  {
    "hostname": "sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "mizunousa",
    "orderMethod": "custom",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoFastpitch",
    "hashtag": "MizunoCustom",
    "hideVia": true,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "softballglove.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "mizunousa",
    "orderMethod": "custom",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoFastpitch",
    "hashtag": "MizunoCustom",
    "hideVia": true,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "mizunousa.softballglove.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "mizunousa",
    "orderMethod": "custom",
    "shopatronId": "1661.4",
    "twitterHandle": "MizunoFastpitch",
    "hashtag": "MizunoCustom",
    "hideVia": true,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "mizunocda.softballglove.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "mizunocda",
    "vendorName": "Mizuno Canada",
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "regions": "canada",
    "twitterHandle": "MizunoFastpitch",
    "hashtag": "MizunoCustom",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "mec.softballglove.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "mec",
    "vendorName": "Mizuno Experience Center",
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Mizuno Experience Center Custom Glove",
    "customerEmailOrderBodyId": "mec",
    "regions": "usa",
    "twitterHandle": "MizunoEXPCenter",
    "hideVia": false,
    "hideSocial": true,
    "currency": "USD"
  },
  {
    "hostname": "prostockathleticsupply.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "prostockathleticsupply",
    "vendorName": "Prostock Athleic Supply",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Prostock Athleic Supply",
    "regions": "canada",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "kirbyssports.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "kirbyssports",
    "vendorName": "Kirbys SFS",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Kirbys SFS",
    "regions": "canada",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "unitedsport.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "unitedsport",
    "vendorName": "United Cycle Source for Sports",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "United Cycle Source for Sports",
    "regions": "canada",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "grandslamcanada.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "grandslamcanada",
    "vendorName": "Grand Slam sports",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Grand Slam sports",
    "regions": "canada",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "baseballtown.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "baseballtown",
    "vendorName": "Baseball Town",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Baseball Town",
    "regions": "canada",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "kahunaverse.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "kahunaverse",
    "vendorName": "Home Run Sports",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Home Run Sports",
    "regions": "canada",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "academy.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "academy",
    "vendorName": "Academy",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Academy",
    "regions": "usa",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "dsg.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "dsg",
    "vendorName": "Dicks Merchandising",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Dicks Merchandising",
    "regions": "usa",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "directsports.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "directsports",
    "vendorName": "Direct Sports Inc",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Direct Sports Inc",
    "regions": "usa",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "baseballsavings.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "baseballsavings",
    "vendorName": "Baseball Savings",
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Baseball Savings",
    "regions": "usa",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "softballfans.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "softballfans",
    "vendorName": "Softball Fans",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Softball Fans",
    "regions": "usa",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "justballgloves.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "justballgloves",
    "vendorName": "Pro Athlete",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Pro Athlete",
    "regions": "usa",
    "addToCartUrl": "UNKNOWN",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "hitadouble.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "hitadouble",
    "vendorName": "HIT a Double",
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "HITaDouble Custom Orders",
    "regions": "usa",
    "hideVia": true,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "svp.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "svp",
    "vendorName": "SVP Retail",
    "orderMethod": "email",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Mizuno Custom Glove Design",
    "regions": "usa",
    "hideVia": true,
    "hideSocial": true,
    "currency": "USD"
  },
  {
    "hostname": "hq4sports.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "hq4sports",
    "vendorName": "HQ4SPORTS",
    "orderMethod": "client-cart",
    "regions": "usa",
    "addToCartUrl": "https://www.kingwebtools.com/hq_4_sports/mizuno_custom_glove/atc.php",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "hq4sports.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "hq4sports",
    "vendorName": "HQ4SPORTS",
    "orderMethod": "client-cart",
    "regions": "usa",
    "addToCartUrl": "https://www.kingwebtools.com/hq_4_sports/mizuno_custom_glove/atc.php",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "baseballexpress.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "baseballexpress",
    "vendorName": "Baseball Express",
    "orderMethod": "client-cart",
    "regions": "usa",
    "addToCartUrl": "https://www.baseballexpress.com/checkout/cart/",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "baseballexpress.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "baseballexpress",
    "vendorName": "Baseball Express",
    "orderMethod": "client-cart",
    "regions": "usa",
    "addToCartUrl": "https://www.baseballexpress.com/checkout/cart/",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "softballcom.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "softballcom",
    "vendorName": "Softball.com",
    "orderMethod": "client-cart",
    "regions": "usa",
    "addToCartUrl": "https://www.softball.com/checkout/cart/",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "softballcom.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "softballcom",
    "vendorName": "Softball.com",
    "orderMethod": "client-cart",
    "regions": "usa",
    "addToCartUrl": "https://www.softball.com/checkout/cart/",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "teamexpress.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "teamexpress",
    "vendorName": "Team Express",
    "orderMethod": "client-cart",
    "regions": "usa",
    "addToCartUrl": "https://www.teamexpress.com/checkout/cart/",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "teamexpress.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "teamexpress",
    "vendorName": "Team Express",
    "orderMethod": "client-cart",
    "regions": "usa",
    "addToCartUrl": "https://www.teamexpress.com/checkout/cart/",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "ebasesloaded.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "ebasesloaded",
    "vendorName": "Bases Loaded",
    "orderMethod": "client-cart",
    "regions": "usa",
    "addToCartUrl": "https://bc.ebasesloaded.com/baseball-glove-builder/process.php",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "ebasesloaded.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "ebasesloaded",
    "vendorName": "Bases Loaded",
    "orderMethod": "client-cart",
    "regions": "usa",
    "addToCartUrl": "https://bc.ebasesloaded.com/softball-glove-builder/process.php",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "smashitsports.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "smashitsports",
    "vendorName": "Smashit Sports Canada",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Smashit Sports Canada",
    "regions": "canada",
    "addToCartUrl": "https://canada.smashitsports.com/?add-to-cart=191560",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "smashitsports.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "smashitsports",
    "vendorName": "Smashit Sports Canada",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Smashit Sports Canada",
    "regions": "canada",
    "addToCartUrl": "https://canada.smashitsports.com/?add-to-cart=191560",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "mvpathleticsupplies.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "mvpathleticsupplies",
    "vendorName": "MVP Athletic Supplies",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "MVP Athletic Supplies",
    "regions": "canada",
    "addToCartUrl": "https://www.mvpathleticsupplies.com/cart",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "mvpathleticsupplies.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "mvpathleticsupplies",
    "vendorName": "MVP Athletic Supplies",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "MVP Athletic Supplies",
    "regions": "canada",
    "addToCartUrl": "https://www.mvpathleticsupplies.com/cart",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "charlierosebaseball.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "charlierosebaseball",
    "vendorName": "Charlie Rose Baseball",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Charlie Rose Baseball",
    "regions": "usa",
    "addToCartUrl": "https://www.charlierosebaseball.com/cart/",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "charlierosebaseball.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "charlierosebaseball",
    "vendorName": "Charlie Rose Baseball",
    "orderMethod": "client-cart",
    "orderEmail": "sublimated.orders@mizunousa.com",
    "orderEmailName": "Charlie Rose Baseball",
    "regions": "usa",
    "addToCartUrl": "https://www.charlierosebaseball.com/cart/",
    "hideVia": false,
    "hideSocial": false,
    "currency": "USD"
  },
  {
    "hostname": "homerunsports.bbg.mizunocustom.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "homerunsports",
    "vendorName": "Home Run Sports",
    "orderMethod": "client-cart",
    "regions": "canada",
    "addToCartUrl": "https://apps.oneteamsports.com/mizuno-custom-glove-builder/mizuno-glove-customizer.php",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  },
  {
    "hostname": "homerunsports.sbg.mizunocustom.com",
    "environment": "production",
    "sportId": "softball",
    "vendorId": "homerunsports",
    "vendorName": "Home Run Sports",
    "orderMethod": "client-cart",
    "regions": "canada",
    "addToCartUrl": "https://apps.oneteamsports.com/mizuno-custom-glove-builder/mizuno-glove-customizer.php",
    "hideVia": false,
    "hideSocial": false,
    "currency": "CAD"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<VendorRow[]>> = data;

export type Vendor = typeof typed[number];

export const VENDOR_INDEX_KEY = "hostname";
export type VendorIndexKey = "hostname";
export type VendorHostname = Vendor["hostname"];

let i = 0;
export const VENDOR_DICT = {
  "bbg-dev.mizunocustom.com": typed[i++],
  "sbg-dev.mizunocustom.com": typed[i++],
  "bbgs.mizunocustom.com": typed[i++],
  "sbgs.mizunocustom.com": typed[i++],
  "bbg-staging.mizunocustom.com": typed[i++],
  "sbg-staging.mizunocustom.com": typed[i++],
  "mec.bbg-staging.mizunocustom.com": typed[i++],
  "mec.sbg-staging.mizunocustom.com": typed[i++],
  "cda.bbg-staging.mizunocustom.com": typed[i++],
  "cda.sbg-staging.mizunocustom.com": typed[i++],
  "bbg.mizunocustom.com": typed[i++],
  "baseballglove.mizunocustom.com": typed[i++],
  "mizunousa.baseballglove.mizunocustom.com": typed[i++],
  "mizunocda.baseballglove.mizunocustom.com": typed[i++],
  "mec.baseballglove.mizunocustom.com": typed[i++],
  "prostockathleticsupply.bbg.mizunocustom.com": typed[i++],
  "kirbyssports.bbg.mizunocustom.com": typed[i++],
  "unitedsport.bbg.mizunocustom.com": typed[i++],
  "grandslamcanada.bbg.mizunocustom.com": typed[i++],
  "baseballtown.bbg.mizunocustom.com": typed[i++],
  "kahunaverse.bbg.mizunocustom.com": typed[i++],
  "academy.bbg.mizunocustom.com": typed[i++],
  "dsg.bbg.mizunocustom.com": typed[i++],
  "directsports.bbg.mizunocustom.com": typed[i++],
  "baseballsavings.bbg.mizunocustom.com": typed[i++],
  "softballfans.bbg.mizunocustom.com": typed[i++],
  "justballgloves.bbg.mizunocustom.com": typed[i++],
  "hitadouble.bbg.mizunocustom.com": typed[i++],
  "svp.bbg.mizunocustom.com": typed[i++],
  "sbg.mizunocustom.com": typed[i++],
  "softballglove.mizunocustom.com": typed[i++],
  "mizunousa.softballglove.mizunocustom.com": typed[i++],
  "mizunocda.softballglove.mizunocustom.com": typed[i++],
  "mec.softballglove.mizunocustom.com": typed[i++],
  "prostockathleticsupply.sbg.mizunocustom.com": typed[i++],
  "kirbyssports.sbg.mizunocustom.com": typed[i++],
  "unitedsport.sbg.mizunocustom.com": typed[i++],
  "grandslamcanada.sbg.mizunocustom.com": typed[i++],
  "baseballtown.sbg.mizunocustom.com": typed[i++],
  "kahunaverse.sbg.mizunocustom.com": typed[i++],
  "academy.sbg.mizunocustom.com": typed[i++],
  "dsg.sbg.mizunocustom.com": typed[i++],
  "directsports.sbg.mizunocustom.com": typed[i++],
  "baseballsavings.sbg.mizunocustom.com": typed[i++],
  "softballfans.sbg.mizunocustom.com": typed[i++],
  "justballgloves.sbg.mizunocustom.com": typed[i++],
  "hitadouble.sbg.mizunocustom.com": typed[i++],
  "svp.sbg.mizunocustom.com": typed[i++],
  "hq4sports.sbg.mizunocustom.com": typed[i++],
  "hq4sports.bbg.mizunocustom.com": typed[i++],
  "baseballexpress.bbg.mizunocustom.com": typed[i++],
  "baseballexpress.sbg.mizunocustom.com": typed[i++],
  "softballcom.bbg.mizunocustom.com": typed[i++],
  "softballcom.sbg.mizunocustom.com": typed[i++],
  "teamexpress.bbg.mizunocustom.com": typed[i++],
  "teamexpress.sbg.mizunocustom.com": typed[i++],
  "ebasesloaded.bbg.mizunocustom.com": typed[i++],
  "ebasesloaded.sbg.mizunocustom.com": typed[i++],
  "smashitsports.bbg.mizunocustom.com": typed[i++],
  "smashitsports.sbg.mizunocustom.com": typed[i++],
  "mvpathleticsupplies.bbg.mizunocustom.com": typed[i++],
  "mvpathleticsupplies.sbg.mizunocustom.com": typed[i++],
  "charlierosebaseball.bbg.mizunocustom.com": typed[i++],
  "charlierosebaseball.sbg.mizunocustom.com": typed[i++],
  "homerunsports.bbg.mizunocustom.com": typed[i++],
  "homerunsports.sbg.mizunocustom.com": typed[i++]
} as const;

export { typed as VENDORS };
